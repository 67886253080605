import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import FullPageSlider from "../components/FullPageSlider/FullPageSlider";
import FullPageSliderWrapper from "../components/FullPageSlider/FullPageSliderWrapper";
import FullPageSliderContent from "../components/FullPageSlider/FullPageSliderContent";

import SectionWithImageSlider from "../components/SectionWithImageSlider/SectionWithImageSlider";
import SectionImageSlider from "../components/SectionWithImageSlider/SectionImageSlider";
import SectionText from "../components/SectionWithImageSlider/SectionText";
import { StyledSectionImage } from "../components/SectionWithImageSlider/SectionImage";
import { getImage } from "gatsby-plugin-image";
import StandardSection from "../components/StandardSection";
import LinkButton from "../components/LinkButton";
import { Helmet } from "react-helmet";
import Gallery from "../components/ImageGallery/Gallery";

const DomekPage = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>Domek u Krysi - Twój dom w Górach!</title>
        <meta
          name="description"
          content="Oferujemy komfortowe noclegi dla 6 osób w drewnianym domku w Wiśle. Z balkonu podziwiać można panoramę gór: pasmo Cieńkowa i Baraniej Góry oraz Dolinę Malinki."
        />
      </Helmet>
      <FullPageSliderWrapper>
        <FullPageSlider images={data.domekBackgrounds.nodes}></FullPageSlider>
        <FullPageSliderContent>
          <h1>Domek u Krysi</h1>
          <h3>Twój dom w Górach!</h3>
        </FullPageSliderContent>
      </FullPageSliderWrapper>

      <SectionWithImageSlider>
        <SectionText>
          <h2>„Domek u Krysi”</h2>
          <section>
            <p>
              Mamy do zaoferowania komfortowe noclegi dla 6 osób w drewnianym
              domku w Wiśle. Z balkonu podziwiać można panoramę gór: pasmo
              Cieńkowa i Baraniej Góry oraz Dolinę Malinki.
            </p>
            <p>
              Latem jest to miejsce dla osób, które chcą odpocząć od codziennego
              zgiełku, blisko lasów i potoków oraz szlaków turystycznych i
              spacerowych. Zimą doskonałe miejsce dla narciarzy, w pobliżu
              wyciągi narciarskie Cieńków, Klepki oraz stacje narciarskie w
              Szczyrku.
            </p>
          </section>
        </SectionText>
        <SectionImageSlider>
          {data.domekSlider.nodes.map((item, index) => {
            return (
              <StyledSectionImage
                image={getImage(item)}
                key={index + "domek_1"}
                alt=""
              ></StyledSectionImage>
            );
          })}
        </SectionImageSlider>
      </SectionWithImageSlider>

      <StandardSection darkBackground={true}>
        <h2>Opis obiektu</h2>

        <div className="columns">
          <div>
            <p>
              <b className="mobileCenter">Parter:</b>
              <br />- Salon: rogówka z funkcją spania, stół i krzesła, TV-SAT,
              bezpłatny internet światłowodowy / WiFi /
            </p>
            <p>
              - Kuchnia z pełnym wyposażeniem: płyta grzewcza z piekarnikiem
              elektrycznym, lodówko-zamrażarka, kuchenka mikrofalowa, zmywarka,
              garnki, talerze, kubki, sztućce itp.
            </p>
            <p>- Łazienka: kabina prysznicowa, umywalka, bidet, WC, pralka</p>
            <br />
            <p>
              Do dyspozycji łóżeczko turystyczne, krzesełko do karmienia,
              suszarka na pranie, żelazko, mały przenośny grill.
            </p>
          </div>

          <div>
            <p>
              <b className="mobileCenter">Piętro:</b>
              <br />- Dwie oddzielne sypialnie (jedna z łożem małżeńskim, a
              druga z dwoma tapczanami) z szafką i miejscem na garderobę
            </p>
            <p>- Łazienka: wanna, WC, umywalka</p>
            <p>
              - W obiekcie obowiązuje całkowity zakaz palenia ale jest
              wyznaczone miejsce na zewnątrz budynku
            </p>
            <p>- Parking przy ulicy</p>
          </div>
        </div>
      </StandardSection>

      <StandardSection>
        <h2>Ważne informacje</h2>
        <p>
          Cena: 420 zł / domek / noc przy min. 3 dobach
          <br />
          Minimalny czas wynajmu 3 doby
        </p>
        <p>
          Rezerwacja telefoniczna:
          <br />
          +48 500 026 168
          <br />
          +48 604 942 921
        </p>
        <p>
          Potwierdzenie rezerwacji jest wpłata zadatku na konto:
          <br />
          Bank PKO BP
          20&nbsp;1020&nbsp;1390&nbsp;0000&nbsp;6102&nbsp;0082&nbsp;3187
        </p>
        <p>
          Przedpłata: obiekt pobiera zadatek w wysokości 30 % kwoty za pobyt.
          <br />
          Zadatek należy wpłacić w ciągu 2 dni od dokonania rezerwacji.
          <br />
          Respektujemy bony turystyczne. Płatność tylko przelewem lub Blikiem!
          <br />
          Zameldowanie od 14:00 w dniu przyjazdu, wymeldowanie do 10:00 w dniu
          wyjazdu.
        </p>
        <LinkButton to="/kontakt" filled={true} marginTop={true}>
          Zapytaj o dostępność
        </LinkButton>
      </StandardSection>

      <StandardSection>
        <h2 className="noTopMargin">Galeria</h2>

        <Gallery items={data.domekGallery.nodes} />
      </StandardSection>
    </Layout>
  );
};

export const query = graphql`
  query {
    domekSlider: allFile(
      filter: { sourceInstanceName: { eq: "domekSlider" } }
      sort: { fields: [name], order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
    domekBackgrounds: allFile(
      filter: { sourceInstanceName: { eq: "domekBackgrounds" } }
      sort: { fields: [name], order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
    domekGallery: allFile(
      filter: { sourceInstanceName: { eq: "domekGallery" } }
      sort: { fields: [name], order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
`;

export default DomekPage;
